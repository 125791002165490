<template>
  <div class="about pt-12 pb-12">
    <v-container>
      <div class="text-p pb-6">NOSSA MISSÃO</div>
      <div class="text-h3 pr-16 pb-16 mb-12">
        Oferecer um ecossistema completo de serviços de mídia em nível Mundial,
        ao alcance de todos, com o máximo de qualidade, eficiência e
        transparência.
      </div>
      <div class="text-h3 text-center pt-16 mt-16">
        Nossa tecnologia entrega<br />qualidade e produtividade
      </div>
      <v-row class="about-data pt-12">
        <v-col>
          <v-sheet
            rounded
            class="pa-9 mx-auto ml-md-auto mr-md-0 text-center"
            width="260"
            ><div class="text-h6"><strong>+2mil</strong> Planos de Mídia para
            <strong>+1mil</strong> clientes</div></v-sheet
          >
        </v-col>
        <v-col>
          <v-sheet rounded class="pa-9 mx-auto text-center" width="260"
            ><div class="text-h6"><strong>+3mil</strong> PIs para <strong>+500</strong> veículos de
            comunicação</div></v-sheet
          >
        </v-col>
        <v-col>
          <v-sheet
            rounded
            class="pa-9 mx-auto mr-md-auto ml-md-0 text-center"
            width="260"
            ><div class="text-h6"><strong>+10 bilhões</strong> de impactos em
            <strong>+200</strong> campanhas</div></v-sheet
          >
        </v-col>
      </v-row>
      <div class="pt-9 pb-16 mb-16 text-h5 text-center font-weight-bold">
        Tudo isso com apenas {{ team.Founders.length + team.Specialists.length}} colaboradores incríveis!
      </div>

      <v-row>
        <v-col>
          <div class="text-h4 pb-6 font-weight-bold">O que fazemos</div>
          <div class="text-p">
            Combinamos tecnologia, pessoas e dados para o desenvolvimento de
            soluções para o mercado de Publicidade e Propaganda como um todo:
            anunciantes, agencias e veículos de comunicação. Desenvolvemos
            tecnologia sempre com propósito, mas sem jamais deixar de lado o
            elemento mais importante nesta jornada: as relações humanas.
            Trabalhamos incansavelmente para promover ativos que agregam valor
            para todos: transparência, imparcialidade, precisão e produtividade.
          </div>
        </v-col>
        <v-col cols="12" md="8" class="pl-16">
          <v-img src="@/assets/images/about-1-min.png" class="ml-md-16"></v-img>
        </v-col>
      </v-row>

      <v-row class="pt-12 mt-12">
        <v-col cols="12" md="8" class="pr-16">
          <v-img src="@/assets/images/about-2-min.png" class="mr-md-16"></v-img>
        </v-col>
        <v-col>
          <div class="text-h4 pb-6 font-weight-bold">Como fazemos</div>
          <div class="text-p">
            Buscamos consolidar em um único lugar as melhores ferramentas
            existentes no mercados, transformamos processos anacrônicos e
            padronizamos um mercado totalmente fragmentado para a implementação
            de verdadeira transformação digital a serviço das empresas e da
            sociedade.
          </div>
          <div class="text-p">
            Com isso, podemos dizer que, por intermédio de nossas soluções,
            resolvemos problemas tradicionais com mais praticidade, rapidez,
            transparência e eficácia.
          </div>
        </v-col>
      </v-row>
      <v-row class="pt-12 mt-12">
        <v-col cols="12" md="9" class="mx-auto">
          <div class="text-h4 pb-6 font-weight-bold text-center">
            Por quê fazemos?
          </div>
          <div class="text-p text-center pb-12">
            Nosso objetivo principal sempre foi a democratização da mídia em
            dois principais aspectos:<br />acessibilidade e inteligência.
            Sabemos que tal disruptura leva tempo, mas uma fez feita, as
            mudanças são profundas! Não somente no mercado, mas culturalmente e
            queremos fazer parte disso.
          </div>
        </v-col>
      </v-row>
      <div class="d-flex flex-column align-center pb-12 mb-12">
        <v-sheet
          max-width="480"
          class="d-flex mr-12 mb-3 pa-2 align-center"
          rounded
        >
          <div class="text-h4 mr-5 ml-3 d-flex align-center justify-center">
            1
          </div>
          <div class="text-p">
            Tornamos a comunicação mais rápida e assertiva de todo ecossistema
            publicitário.
          </div>
        </v-sheet>
        <v-sheet
          max-width="480"
          class="d-flex ml-12 mb-3 pa-2 align-center"
          rounded
          color="accent"
        >
          <div class="text-h4 mr-5 ml-3 align-center justify-center">2</div>
          <div class="text-p">
            Promovemos o acesso a informação de maneira acessível e
            descomplicada.
          </div>
        </v-sheet>
        <v-sheet
          max-width="480"
          class="d-flex mr-12 mb-3 pa-2 align-center"
          rounded
        >
          <div class="text-h4 mr-5 ml-3 align-center justify-center">3</div>
          <div class="text-p">
            Automatizamos processos e diminuímos a burocratização.
          </div>
        </v-sheet>
        <v-sheet
          max-width="480"
          class="d-flex ml-12 mb-3 pa-2 align-center"
          rounded
          color="accent"
        >
          <div class="text-h4 mr-5 ml-3 align-center justify-center">4</div>
          <div class="text-p">
            Favorecemos as tomadas de decisões através de dados.
          </div>
        </v-sheet>
        <v-sheet
          max-width="480"
          class="d-flex mr-12 mb-3 pa-2 align-center"
          rounded
        >
          <div class="text-h4 mr-5 ml-3 align-center justify-center">5</div>
          <div class="text-p">
            Facilitamos a análise de desempenho através de ferramentas de
            performance modernas.
          </div>
        </v-sheet>
      </div>

      <div class="text-h4 font-weight-bold pt-12 pb-6">
        Conheça quem faz a mudança!
      </div>
      <div class="text-p">
        Criamos tecnologia, aperfeiçoamos produtos e processos, mas é a paixão,
        o respeito pelos clientes, fornecedores e demais stakeholders, e o
        compromisso com resultados que torna essa equipe realmente especial.
      </div>
      <div v-if="!loading">
        <template v-if="team.Founders && team.Founders.length > 0">
          <div class="text-h5 pt-12 pb-3">Founders</div>
          <v-row class="pb-6">
            <v-col
              cols="12"
              md="3"
              sm="4"
              v-for="(person, index) in team.Founders"
              :key="index"
            >
              <v-img
                :src="person.coverage"
                width="200"
                height="200"
                class="-person"
              ></v-img>
              <div class="text-p font-weight-bold">{{ person.name }}</div>
              <div class="text-p">{{ person.legend }}</div>
            </v-col>
          </v-row>
        </template>
        <template v-if="team.Specialists && team.Specialists.length > 0">
          <div class="text-h5 pt-12 pb-3">Especialistas</div>

          <v-row class="pb-6">
            <v-col
              cols="12"
              md="3"
              sm="4"
              v-for="(person, index) in team.Specialists"
              :key="index"
            >
              <v-img
                :src="person.coverage"
                cover
                width="200"
                height="200"
                class="-person"
              ></v-img>
              <div class="text-p font-weight-bold">{{ person.name }}</div>
              <div class="text-p">{{ person.legend }}</div>
            </v-col>
          </v-row>
        </template>
        <template v-if="team.Advisors && team.Advisors.length > 0">
          <div class="text-h5 pt-12 pb-3">Advisors</div>

          <v-row class="pb-6">
            <v-col
              cols="12"
              md="3"
              sm="4"
              v-for="(person, index) in team.Advisors"
              :key="index"
            >
              <v-img
                :src="person.coverage"
                cover
                width="200"
                height="200"
                class="-person"
              ></v-img>
              <div class="text-p font-weight-bold">{{ person.name }}</div>
              <div class="text-p">{{ person.legend }}</div>
            </v-col>
          </v-row>
        </template>
      </div>
    </v-container>
    <div class="--slider">
      <v-container ref="ourHistory">
        <v-img
          src="@/assets/images/cube.svg"
          height="50"
          width="50"
          contain
          class="mb-12"
        ></v-img>
        <div class="d-flex justify-space-between align-end mb-6" slot="default">
          <div>
            <div class="text-h4 font-weight-bold pb-6">Nossa História</div>
            <div class="text-p">
              Somos uma startup brasileira sediada em São Paulo, com negócios
              dentro e fora do Brasil, muita humildade, força de vontade e
              histórias incríveis para contar.<br />
              Se tiver paciência, resumimos aqui alguns tropeços e conquistas...
            </div>
          </div>
          <div class="d-flex ml-12">
            <v-btn fab small color="white" light @click="clickLeft"
              ><v-icon>mdi-arrow-left</v-icon></v-btn
            >
            <v-btn
              fab
              small
              class="ml-6"
              color="white"
              light
              @click="clickRight"
              ><v-icon>mdi-arrow-right</v-icon></v-btn
            >
          </div>
        </div>
      </v-container>
      <v-slide-group center-active v-model="historyActive" :show-arrows="true">
        <v-slide-item
          v-for="(doc, index) in steps"
          :key="index"
          v-slot="{ active, toggle }"
        >
          <v-card
            :color="active ? 'primary' : ''"
            max-width="394"
            class="px-1"
            @click="toggle"
            :style="
              styling(marginPosition, index == 0, index == steps.length - 1)
            "
          >
            <v-card-title v-html="doc.title"></v-card-title>
            <v-card-subtitle>{{ doc.date }}</v-card-subtitle>
            <v-card-text>
              <div v-html="doc.text" class="limit-3-lines"></div>
            </v-card-text>
          </v-card>
        </v-slide-item>
        <template v-slot:prev="{ on, attrs }">
          <v-btn
            fab
            small
            color="white"
            light
            v-bind="attrs"
            v-on="on"
            id="slideBack"
            style="display: none"
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-btn
            fab
            small
            color="white"
            light
            v-bind="attrs"
            id="slideNext"
            style="display: none"
            v-on="on"
            ><v-icon>mdi-arrow-right</v-icon></v-btn
          >
        </template>
      </v-slide-group>
    </div>
    <div class="time-line py-12 d-flex justify-space-around">
      <div class="time-line- mt-13"></div>
      <div
        class="time-line-item"
        :class="{ active: historyActive == index }"
        v-for="(item, index) in steps"
        :key="index"
        @click="historyActive = index"
      >
        <v-img
          v-if="item.icon"
          :src="require(`@/assets/images/${item.icon}`)"
          contain
          height="31"
          class="mb-1 mx-auto"
        ></v-img>
        <v-avatar
          v-else
          color="black"
          size="31"
          class="mx-auto mb-1"
        ></v-avatar>
        <div class="d-flex justify-center pt-2">
          <v-btn
            :class="historyActive == index ? 'btn-active' : ''"
            style="width: 18px; height: 18px"
            x-small
            fab
            :color="historyActive == index ? 'primary' : ''"
          >
            <v-avatar color="#707070" size="12"
              ><v-avatar color="#fff" size="8"></v-avatar
            ></v-avatar>
          </v-btn>
        </div>
        <div
          class="text-caption py-1 text-center text--disabled font-weight-bold"
        >
          {{ item.date }}
        </div>
      </div>
    </div>

    <v-container>
      <work-with-us></work-with-us>
    </v-container>
  </div>
</template>
<script>
import WorkWithUs from "../components/Home/WorkWithUs.vue";
export default {
  name: "About",
  components: {
    WorkWithUs,
  },
  data() {
    return {
      slide: null,
      e1: "2015",
      historyActive: null,
      documents: [],
      team: {},
      loading: true,
      steps: [
        {
          date: "2015",
          icon: "large_logo.svg",
          title: "Fundação da empresa",
          text: "Com base na dificuldade para encontrar, planejar, comprar mídia num mercado com grande fragmentação e assimetria de dados, concentração de verbas e carência de ferramentas eficientes, foi idealizada uma solução ousada para acelerar a transformação digital de todo o ecossistema de negócios publicitários.<br>Empresários, executivos de marketing e especialistas do setor apoiaram o plano de negócio dos fundadores, que captaram o investimento semente e criaram a The Media Trade.",
        },
        {
          date: "2016",
          icon: null,
          title: "Primeiros parceiros e veículos",
          text: "Instalamos nosso quartel general numa edícula atrás do escritório de uma gestora de investimentos e começamos e escrever os primeiros códigos do sistema, criar banco de dados e demais recursos que sempre demoram mais do que o previsto.<br>Ao final da 1ª semana já tínhamos homologado a Media Trade no Metrô de São Paulo para vender os espaços publicitários com preços muito menores do que a concorrência, pois desde o início decidimos que não aplicaríamos mark’up, operando de forma transparente com anunciantes, agências e veículos, sem inflacionar o mercado.<br>A partir do 4º mês conquistamos nossos primeiros clientes e nos esforçávamos muito para entregar as campanhas com alta velocidade, qualidade e precisão, mesmo com processos ainda manuais e uma plataforma em beta que não era muito boa.",
        },
        {
          date: "2017",
          icon: "cda-head.svg",
          title: "Lançamento Canal do Anúncio",
          text: "Começamos o ano com grandes dificuldades, sem 2 dos 3 fundadores iniciais, 1 analista de TI que depois veio a ser nosso admirado CTO e uma estagiária super competente e que dançava super bem! O caixa da empresa estava baixíssimo e tivemos de mudar para um novo escritório, uma salinha bem aconchegante no Ahead do Largo da Batata.<br>Lançamos nossa plataforma! Mirávamos pequenas e médias empresas e tínhamos sempre que soletrar T-h-e M-e-d-i-a T-r-a-d-e., então para facilitar batizamos de Canal do Anúncio o primeiro marketplace multimeios do Brasil, contando com mais de 200 veículos de mídia parceiros e um inventário de quase 10 mil formatos e espaços publicitários. As interfaces não eram muito amigáveis, mas qualquer pessoa já conseguia filtrar a mídia desejada, agendar as veiculações e pagar pelo sistema.",
        },
        {
          date: "2018",
          title: "SaaS para agências",
          icon: "cdm-head.svg",
          text: "O Canal do Anúncio foi notado por alguns profissionais de mídia, que começaram a nos questionar por que não tínhamos uma plataforma específica para atender suas agências, onde pudessem gerar planos de mídia customizados para seus clientes, gerasse comissionamento e ainda contribuísse com o gerenciamento das campanhas. Pronto! Criamos o Canal do Mídia, que já contava com seus primeiros assinantes no mês de lançamento, e passamos a ouvir feedbacks como “caramba, você quebraram um galhão, viu!” e “pô, eu levava horas para mapear, negociar, consolidar os planos de mídia, agora é tudo automático, consigo entender e analisar melhor a estratégia, trabalhar oportunidades, e ainda chego em casa para a hora do jantar.”. Tudo isso nos deu muito orgulho!",
        },
      ],
    };
  },
  mounted() {
    this.loadTeam();
    let leftPosition = this.$refs.ourHistory.getBoundingClientRect().left;
    this.marginPosition = leftPosition;
  },

  methods: {
    styling(m, f, l) {
      let style = "";
      if (f) {
        style += `margin-left: ${m}px;`;
      }
      if (l) {
        style += `margin-right: ${m}px `;
      }
      return style;
    },
    nextStep(n) {
      this.e1 = this.steps[n + 1].date;
    },
    loadTeam() {
      const cats = {};
      const q = this.$query(
        this.$collection(this.$db, "Team"),
        this.$where("status", "==", 1)
      );
      const querySnapshot = this.$getDocs(q);
      querySnapshot.then((snap) => {
        // console.log("snap", snap);
        const documents = snap.docs.map((doc) => doc.data());
        documents.forEach((person) => {
          console.log("person", person);
          if (!Object.prototype.hasOwnProperty.call(cats, person.category)) {
            cats[person.category] = [];
          }
          cats[person.category].push(person);
        });
        this.documents = documents;
        this.team = cats;
        this.loading = false;
      });
    },
    clickLeft() {
      const LEFT = document.getElementById("slideBack");
      LEFT.click();
      // this.checkNavBtnStatus();
    },
    clickRight() {
      const RIGHT = document.getElementById("slideNext");
      RIGHT.click();
      // console.log("TO RIGHT!!!");

      // this.nextButton.click();
      // this.checkNavBtnStatus();
    },
  },
};
</script>

<style lang="scss">
.about {
  .v-stepper__step__step {
    height: 48px;
    min-width: 48px;
    width: 48px;
    margin-bottom: 5px;
  }
  .v-stepper__step {
    padding: 0;
    // margin: 0 -75px;
  }
  .about-data {
    strong {
      color: #2a4a9d;
    }
  }
  .v-slide-group__prev,
  .v-slide-group__next {
    display: none;
  }
  .v-card {
    @media screen and (min-width: 960px) {
      margin: 0 50px 0 0;
    }
  }
  .--continue-reading {
    bottom: -12px;
  }
  .limit-3-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .time-line {
    height: 100px;
  }
  .time-line- {
    height: 1px;
    width: 100%;
    position: absolute;
    background: #707070;
  }
  .time-line-item {
    width: 150px;
  }
  .btn-active * {
    opacity: 0;
  }
  .v-card .primary {
    background-color: #2196f3 !important;
    border-color: #2196f3 !important;
  }
  .-person {
    filter: grayscale(1);
    transition: 0.3s ease-in-out all;
    &:hover {
      filter: grayscale(0);
    }
  }
}
</style>